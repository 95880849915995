import { Menu } from 'antd';
import styled from 'styled-components';
import {
  Link,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { GROUPDETAILS, SEARCH_ROUTE } from 'Utils/Constants';
import { selectLoading, selectRecentSearches } from 'Selector/GlobalSearchContentSelector';
import { useDispatch, useSelector } from 'react-redux';
import { Section } from 'Types/GlobalSearchContentTypes';
import { actions } from 'Slice/GlobalSearchContentSlice';
import { isSalesforce } from 'Utils/UtilityFunctions';
import {
  primaryFont,
  wMedium,
  primaryBgColor,
  primaryColor,
  primaryHeadingColor,
  maxWidth,
  retina,
  maxMobile,
} from '../../../Utils/utilsStyle';

const MenuWrapper = styled.div`
  color:red;
  .m-left-50 {
    margin-left: 50px;
    @media all and (max-width: ${maxMobile}) {
     margin-left: 20px;
    }
  }
  .recent-search-text {
    color: #979797;
    font-weight: ${wMedium};
    font-size: 12px;
    line-height: 20px;
    font-family: ${primaryFont};
    margin-top: 18px;
    margin-left: 16px;
    margin-bottom: 4px;
    letter-spacing: -0.38px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size: 13px;
      line-height: 24px;
    }
    @media all and (max-width: ${maxMobile}) {
      margin-left: 10px;
    }

  }
  .recent-search-item {
    color: ${primaryHeadingColor};
    font-family: ${primaryFont};
    font-weight: ${wMedium};
    line-height: 20px;
    font-size: 14px;
    margin-left: 16px;
    margin-bottom: 8px;
    padding:0px;
    letter-spacing: -0.24px;
    height: auto;
    background-color: transparent !important;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      line-height: 24px;
      font-size: 12px;
    }
    @media all and (max-width: ${maxMobile}) {
     margin-left: 10px;
    }

    &:focus {
      background-color: transparent;
    }
    a {
      &:hover {
        color: ${primaryColor}
      }
    }
  }
  .recent-search-item:last-child {
    margin-bottom: 18px;
  }
  .trending-topics-menu {
    background-color: ${primaryBgColor};
  }
  .padded-heading{
    padding: 5px 0px 10px 0px;
    border-radius: 6px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      padding: 5px 0px 10px 0px;
      border-radius: 6px;
    }
  }
  .ant-menu-item-divider {
    margin: 0px;
    border:none;
  }
`;

type Props = {
  getScreenNameFun: () => void;
};

export const GlobalSearchMenu = function GlobalSearchMenu(props: Props) {
  const dispatch = useDispatch();
  const { getScreenNameFun } = props;
  const { pathname } = useLocation();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get('groupId') || '';
  const groupIdParsed = groupId ? parseInt(groupId, 10) : 0;
  const loading: boolean = useSelector(selectLoading);
  const recentSearches: Section = useSelector(selectRecentSearches) as Section;
  const reversedSearches = recentSearches.searches ? [...recentSearches.searches].reverse() : [];
  const currentPath = location.pathname + location.search;
  // const dummyTrendingSearches: string[] = ['Metaverse', 'NFTs', 'DAOs'];

  const clickRecentSearch = (search: string) => {
    getScreenNameFun();
    dispatch(actions.updateRecentSearch(search));
  };
  const route = (search: string) => {
    let path = '';

    if (!isSalesforce()) {
      path = `${SEARCH_ROUTE}${search?.replace(/&/g, '%26')}`;
      if (pathname.includes(GROUPDETAILS) && groupId && parseInt(groupId, 10) > 0) {
        path = `${path}&groupId=${groupId}`;
      }
    } else {
      const section = searchParams.get('extend') || '';
      path = `${SEARCH_ROUTE}${search?.replace(/&/g, '%26')}&extend=${section}`;
    }
    return path;
  };
  return (
    loading === true ? <> </>
      : (
        <MenuWrapper>
          <Menu>
            {reversedSearches?.length
              ? (
                <div className="recent-search-text m-left-50">
                  {`${recentSearches.title}`}
                </div>
              )
              : (
                <div className="recent-search-text m-left-50">
                  <p>No Recent Search found</p>
                </div>
              )}
            {reversedSearches.map((search) => (
              <Menu.Item className="recent-search-item ">
                <Link
                  onClick={() => clickRecentSearch(search)}
                  to={route(search)}
                  state={!isSalesforce() ? {
                    enableBackButton: true,
                    query: search,
                    previousPath: currentPath,
                    ...(pathname.includes(GROUPDETAILS)

                      && groupIdParsed > 0 && { group_id: groupIdParsed }),
                  } : {}}
                >
                  {search}
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </MenuWrapper>
      )
  );
};
