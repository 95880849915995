import { useDispatch, useSelector } from 'react-redux';
import { selectCompanyProfile } from 'Selector/CompaniesSelector';
import { personProfileName } from 'Selector/PersonProfileSelector';
import { actions as personProfileActions } from 'Slice/PersonProfileSlice';
 import { actions as docHubActions } from 'Slice/DocumentHubSlice';
import { PopupValuesType } from 'Types/PersonTypes';
import { CertificationActionPayload, DeleteGroup, DocumentPreviewRequest, DownloadCompanyProfileRequest, GetAllGroups, GetPersonProfileRequest } from 'Types/RequestBodies';
import { actions as companyProfileActions } from 'Slice/CompaniesSlice';
import { actions as onboardingActions } from 'Slice/OnboardingSlice';
import { actions as SRIActions } from 'Slice/SRISlice';
import { CompanyDownloadProfile } from 'Types/CompaniesTypes';
import { actions as searchTopicsActions } from 'Slice/GlobalSearchContentSlice';
import { selectActivetype, selectAiCertificationData, selectAiCertificationLoading, selectCertificationPrompt, selectHighlight, selectIsAiCertificationCompleted, selectIsCompletionPopupOpen, selectIsHighlighted, selectIsScroll, selectShowAccordion } from 'Selector/CertificationSelector';
import { selectDisclessPersonality, selectGilroyDiscalimer, selectGilroyPopup, selectShowDocumentHub, selectShowUploadDocument } from 'Selector/GlobalSearchContentSelector';
import { isEditChatOpen, isExpandFlag, isGilroyTextHeight, isShowAskGif, isShowGilroyGlobal } from 'Selector/UISelector';
import { actions as certificationActions } from 'Slice/CertificationSlice';
import { actions as documentHubActions } from 'Slice/DocumentHubSlice';
import { selectTopPeople } from 'Selector/BuyerGroupSelector';
import { actions as FeedActions } from 'Slice/FeedActivitiesSlice';
import { selectLoading } from 'Selector/SpotlightSelector';
import { CertificationPrompt } from 'Types/CertificationTypes';
import { selectFileViewURL } from 'Selector/DocumentHubSelector';
import { selectCategoryExecutivesSearched, selectCategoryOrganizationExecutives, selectDeletingLoader, selectExecutivesEnded, selectExecutivesLoading, selectExecutivesPage, selectGroupShared, selectOrganizationExecutiveForGilroy, selectSearchedEnded, selectSearchedPage } from 'Selector/GroupsSelector';
import { actions as groupActions } from 'Slice/GroupsSlice';
import { SelectedElements } from 'Types/GroupsTypes';

export const useGilroy = () => {
  const dispatch = useDispatch();
  const personName = useSelector(personProfileName);
  const companyProfile = useSelector(selectCompanyProfile);
  const isHighlight = useSelector(selectHighlight);
  const showAccordion = useSelector(selectShowAccordion);
  const currentType = useSelector(selectActivetype);
  const showGilroyTooltip = useSelector(selectGilroyPopup);
  const showAskGif = useSelector(isShowAskGif);
  const isEditChatOpenFlag = useSelector(isEditChatOpen);
  const isHeightContent = useSelector(isGilroyTextHeight);
  const showDocumentHub = useSelector(selectShowDocumentHub);
  const isScroll = useSelector(selectIsScroll);
  const isHighlighted = useSelector(selectIsHighlighted);
  const aiCertificationData = useSelector(selectAiCertificationData);
  const isCompletionPopupOpen = useSelector(selectIsCompletionPopupOpen);
  const openGilroy = useSelector(isShowGilroyGlobal);
  const gilroyDisclaimer = useSelector(selectGilroyDiscalimer);
  const showUploadDocument = useSelector(selectShowUploadDocument);
  const disclessPersonality = useSelector(selectDisclessPersonality);
  const topPeople = useSelector(selectTopPeople);
  const isExpand = useSelector(isExpandFlag);
  const certificationPrompt = useSelector(selectCertificationPrompt);
  const spotlightLoading = useSelector(selectLoading);
  const url = useSelector(selectFileViewURL);
  const groupShared = useSelector(selectGroupShared);
  const deleteLoader = useSelector(selectDeletingLoader);
  const aiLoading = useSelector(selectAiCertificationLoading);
  const isAiCertificationCompleted = useSelector(selectIsAiCertificationCompleted);
  const searchedPage = useSelector(selectSearchedPage);
  const executivesLoading = useSelector(selectExecutivesLoading);
  const executivesEnded = useSelector(selectExecutivesEnded);
  const searchedEnded = useSelector(selectSearchedEnded);
  const executivesPage = useSelector(selectExecutivesPage);
  const allExecutives = useSelector(selectCategoryOrganizationExecutives);
  const searchedExecutives = useSelector(selectCategoryExecutivesSearched);
  const organizationExecutiveForGilroy = useSelector(selectOrganizationExecutiveForGilroy);


  const gilroyStates = {
    personName,
    companyProfile,
    isHighlight,
    showAccordion,
    currentType,
    showGilroyTooltip,
    showAskGif,
    isEditChatOpenFlag,
    isHeightContent,
    showDocumentHub,
    isScroll,
    isHighlighted,
    aiCertificationData,
    isCompletionPopupOpen,
    openGilroy,
    gilroyDisclaimer,
    showUploadDocument,
    disclessPersonality,
    topPeople,
    isExpand,
    certificationPrompt,
    spotlightLoading,
    url,
    groupShared,
    deleteLoader,
    aiLoading,
    isAiCertificationCompleted,
    searchedPage,
    executivesEnded,
    executivesLoading,
    searchedEnded,
    executivesPage,
    allExecutives,
    searchedExecutives,
    organizationExecutiveForGilroy,
  };

  const gilroyActions = {
    setPopupValues: (data: PopupValuesType) =>  dispatch(personProfileActions.setPopupValues(data)),
    resetPersonPopups: () =>  dispatch(personProfileActions.resetPersonPopups()),
    getPreviewDocument: (data : DocumentPreviewRequest) => dispatch(docHubActions.getPreviewDocument(data)),
    setShowLimitPopupSAP : (data: boolean) => dispatch(companyProfileActions.setShowLimitPopupSAP(data)),
    resetAddCompanyPopup : () => {
      dispatch(companyProfileActions.resetAddCompanyPopup());
      dispatch(onboardingActions.resetAddCompanyPopup());
    },
    resetCompanyProfilePopup : () =>  dispatch(companyProfileActions.resetCompanyProfilePopup()),
    setPPTProfile: (data: CompanyDownloadProfile | null) => dispatch(companyProfileActions.setPPTProfile(data)),
    setPDFProfile: (data: CompanyDownloadProfile | null) => dispatch(companyProfileActions.setPDFProfile(data)),
    resetSRICompanyPopup: () => dispatch(SRIActions.resetSRICompanyPopup()),
    getPersonProfileDownload: (data: GetPersonProfileRequest) => dispatch(personProfileActions.getPersonProfileDownload(data)),
    getPPTProfile: (data: DownloadCompanyProfileRequest) => dispatch(companyProfileActions.getPPTProfile(data)),
    getPDFProfile: (data: DownloadCompanyProfileRequest) => dispatch(companyProfileActions.getPDFProfile(data)),
    updateGilroyTooltip: () => dispatch(searchTopicsActions.updateGilroyTooltip()),
    setHighlight: (data: boolean) => dispatch(certificationActions.setHighlight(data)),
    setIsHighlighted: (data: boolean) => dispatch(certificationActions.setIsHighlighted(data)),
    setDrawerOpen: (data: boolean) => dispatch(documentHubActions.setDrawerOpen(data)),
    aiCertificationAction: (data: CertificationActionPayload) => dispatch(certificationActions.aiCertificationAction(data)),
    pushCurrentSection: (data: string) => dispatch(FeedActions.pushCurrentSection(data)),
    removeCurrentSection: (data: string) => dispatch(FeedActions.removeCurrentSection(data)),
    setOpenGilroyDrawer: (data: boolean) => dispatch(certificationActions.setOpenGilroyDrawer(data)),
    setOpenDrawer: (data: boolean) => dispatch(certificationActions.setOpenDrawer(data)),
    setCertificationPrompt: (data: CertificationPrompt) =>  dispatch(certificationActions.setCertificationPrompt(data)),
    clearPreviewDocument: () =>  dispatch(documentHubActions.clearPreviewDocument()),
    deleteGroup: (data: DeleteGroup) =>  dispatch(groupActions.deleteGroup(data)),
    setSelectedGroups: (data: SelectedElements[]) =>  dispatch(groupActions.setSelectedGroups(data)),
    resetRecentSearches: () =>  dispatch(searchTopicsActions.resetRecentSearches()),
    getOrganizationExecutives: (data: GetAllGroups) =>  dispatch(groupActions.getOrganizationExecutives(data)),
    resetSearchedExecutives: () =>   dispatch(groupActions.resetSearchedExecutives()),
  }

  return {gilroyStates, gilroyActions };
};