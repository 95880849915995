/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { Modal, Row, Col } from 'antd';
import PeopleCardLarge from 'Components/common/components/PeopleCardLarge';
import PencilIcon from 'Components/common/assets/svgIcons/PencilIcon';
import FilterPopOver from 'Components/common/components/FilterPopOver';
import { Executive } from 'Types/PersonTypes';
import {
  maxMobile,
  maxTablet,
  maxWidth, minWidthApp, primaryHeadingColor, retina, secondaryFont, wMedium,
} from 'Utils/utilsStyle';
import styled from 'styled-components';
import {
  allValueInKeyOfArrayExists, anyValueInKeyOfArrayExists, backgroundColorParsing, formatNumberWithCommas, IsTablet,
  valueToObject,
} from 'Utils/UtilityFunctions';
import { actions as groupActions } from 'Slice/GroupsSlice';
import PersonSkeleton from 'Components/common/skeleton/PersonSkeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useEffect, useState } from 'react';
import { FollowedPeopleRequest, UpdatePersonProfileFollow } from 'Types/RequestBodies';
import { actions as peopleFollowActions } from 'Slice/PeopleFollowSlice';
import { actions as peopleActions } from 'Slice/PeopleSlice';
import DeleteIcon from 'Components/common/assets/svgIcons/DeleteIcon';
import { useDispatch, useSelector } from 'react-redux';
import CrossCircle from 'Components/common/assets/svgIcons/CrossCircle';
import { Filter } from 'Types/FilterTypes';
import NoPersonsFound from 'Components/common/components/NoPersonsFound';
import {
  FILE_DOWNLOAD_FAIL, FILE_DOWNLOAD_SUCCESS, TOOLTIP_PEOPLEIFOLLOW, translateSkipAreas,
} from 'Utils/Constants';
import HeadingWithToolTip from 'Components/common/components/HeadingWithToolTip';
import Cross from 'Components/globalSearch/assets/Cross';
import GlobalButton from 'Components/common/components/GlobalButton';
import ExportIcon from 'Components/common/assets/svgIcons/ExportIcon';
import {
  selectExportFollowLoading, selectExportPeopleUrl, selectFollowedPeopleEnded, selectPeopleRemoved,
} from 'Selector/PeopleSelector';
import { actions as helperActions } from 'Slice/HelperSlice';
import { usePrevious } from 'Utils/hooks/usePrevious';
import {
  selectIsScroll, selectHighlight, selectActivetype, selectIsHighlighted,
} from 'Selector/CertificationSelector';
import { selectDisclessPersonality } from 'Selector/GlobalSearchContentSelector';
import DragWrapper from './DragWrapper';
import { actions as certificationActions } from '../../../Slice/CertificationSlice';

const ActionWrapper = styled.div`
  margin-bottom: 30px;
  .filter-people-dashboard {
    .loading-tab {
      height: 30px;
    }
    .tabs-wrapper {
      .tabs-item {
        &.spin-tablet {
          background-color: #FFFFFF;
          border-radius: 8px;
          border: 1px solid #DADADA;
          padding: 0px 6px;
          @media all and (max-width: ${minWidthApp}) {
            margin-left: 10px;
          }
          .ant-spin-spinning {
            margin-top: 4px;
            .ant-spin-dot {
              font-size: 17px;
            }
          }
        }
      }
    }
  }

  .export-people {
    .export_btn {
      svg {
        width: 17px;
        min-width: 17px;
        margin-right: 10px;
        @media all and (max-width: ${maxWidth}), ${retina} {
          width: 12px;
          min-width: 12px;
        }
      }
    }
    @media all and (max-width: ${maxTablet}){ 
      width: 30px;
    .export_btn {
      width: 31px;
      height: 28px;
      padding: 0px 9px;
      svg {
        width: 14px;
        min-width: 14px;
      }
    }
    }
  } 

  &.smMob-wrapper {
    margin-top: 20px;
    @media all and (max-width: ${minWidthApp}) and (min-width: ${maxTablet}) {
      display: flex;
      flex-direction: column;
      .staff-btn-style {
        justify-content: flex-end;
        margin-top: 15px;
      }
    }
    @media all and (max-width: ${maxMobile}){
      display: flex;
      flex-direction: column;
      .other-side {
        justify-content: flex-end;
        padding-right: 20px;
        .svg-style {
          svg {
            min-width: 17px;
          }
        }
      }
    }
    .icon-mode {
      @media all and (max-width: ${maxMobile}){
      margin-right: 0px;
      }
      .anticon-search {
        svg {
          @media all and (max-width: ${maxMobile}){
          height: 16px;
          }
        }
      }
    }
  }
  .other-side {
    @media all and (max-width: ${maxTablet}) {
      .icon-mode {
        margin-right: 4px;
        margin-bottom: 2px;
      }
    }
    > .tabs-item {
    @media all and (max-width: ${maxWidth}) , ${retina} {
     padding: 5px 16px 7px 10px;
    }
    @media all and (max-width: ${minWidthApp}) and (min-width: ${maxTablet}) {
      margin-right: -3px;
    }
    &.svg-style {
      @media all and (max-width: ${maxTablet}) {
        padding: 1px 5px 6px 7px;
        height: 28px;
        width: 30px;
        margin-right: 0px;
      }
    }
    &.cancel-space {
      @media all and (max-width: ${maxTablet}) {
        height: 28px;
        width: 30px;
        margin-left: 10px;
        padding: 1px 5px 6px 7px;
      }
    }
   }
   .large-mode {
    @media all and (max-width: ${minWidthApp}){
      min-width: 200px;
    }
    @media all and (max-width: ${maxMobile}) {
      min-width: 180px;
    }
   }
  }
  .tabs-item {
    cursor: pointer;
    &.svg-style {
      &.remove-space {
          @media all and (max-width: ${minWidthApp}) and (min-width: ${maxTablet}) {
           margin-right: 8px;
          }
        &.icon-mode-cancel {
          width: 34px;
          svg {
            padding-top: 0px;
            margin-right: 0px;
            min-height: 17px;
            margin-top: 3px;
            margin-left: 2px;
            height: 17px;
          }
        }
      }
     svg {
        margin-right: 12px;
        height: 19px;
        padding-top: 3px;
      @media all and (max-width: ${maxWidth}) , ${retina} { 
        margin-right: 4px;
        height: 14px;
        padding-top: 4px;
      }
      @media all and (max-width: ${maxTablet}) { 
       height: 19px;
      }
     }
    }
  }
  .ant-btn:hover, .ant-btn:focus {
    border-color:#DADADA;
    color: #7E7E7E;
  }
  .cancel-space {
    &.icon-mode-cancel {
      @media all and (max-width: ${maxTablet}) {
        svg {
            min-width: 17px;
            margin-right: 0px;
            padding-top: 0px;
            margin-left: -8px;
            margin-top: -4px;
          path {
            fill:#7E7E7E;
          }
        } 
      }
    }
    padding: 9px 15px;
    @media all and (max-width: ${maxWidth}) , ${retina} { 
      padding: 7px 15px !important;
    }
  }
`;

const PopupModal = styled(Modal)`
  .ant-modal-content{
    border-radius: 18px;
    text-align: center;

    .ant-modal-footer{
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;
    }
  }
`;

const Heading = styled.h3`
  display: flex;
  align-items: center;
  font-size: 28px;
  line-height: 30px;
  font-weight: ${wMedium};
  font-family: ${secondaryFont};
  margin-bottom: 0px;
  margin-left: 20px;
  color: ${primaryHeadingColor};
  @media all and (max-width: ${maxWidth}) , ${retina} { 
    font-size: 24px;
  }
  @media all and (max-width: ${maxTablet}) {
    font-size: 19px;
  }
`;

type Props = {
  peopleCount: number
  followedPeople: Executive[]
  filters: Filter[]
  followedLoading: boolean
  moreFollowedLoading: boolean
  fetchMorePeople: ({ offset, filters, query }: FollowedPeopleRequest) => void
  applyFilters: ({ offset, filters, query }: FollowedPeopleRequest) => void
  isDrag: (d: boolean) => void
};

export const PeoplesSkeleton = function followedPeopleLoading() {
  return (
    <Row className="Grid-width-row lastTwoRemove" gutter={[24, 24]} style={{ margin: '0' }}>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => (
        <Col xxl={{ span: 4 }} xl={{ span: 4 }} lg={{ span: 6 }} className="gutter-row">
          <PersonSkeleton key={i} diameterSm="120px" />
        </Col>
      ))}
    </Row>
  );
};

const PeopleIFollow = function PeopleIFollow(props: Props) {
  const {
    peopleCount, followedLoading, followedPeople, fetchMorePeople,
    moreFollowedLoading, filters, applyFilters, isDrag,
  } = props;
  const formattedPeopleCount = formatNumberWithCommas(peopleCount);
  const dispatch = useDispatch();
  const isTab = IsTablet();
  const previousPeople = usePrevious(followedPeople);
  const [filter, setFilter] = useState<Filter[]>([]);
  const [appliedFilters, setAppliedFilters] = useState<Filter[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedPerson, setSelectedPerson] = useState<number[]>([]);
  const [editEnable, setEditEnable] = useState(false);
  const [isFilterUnable, setIsFilterUnable] = useState(true);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const exportFollowLoading = useSelector(selectExportFollowLoading);
  const ended = useSelector(selectFollowedPeopleEnded);
  const url = useSelector(selectExportPeopleUrl);
  const host = window.location.href;

  const peopleRemoved = useSelector(selectPeopleRemoved);
  useEffect(() => {
    if (!editEnable) { setSelectedPerson([]); }
  }, [editEnable]);
  useEffect(() => {
    if (filters.length > 0 && filter.length < 1) {
      const tempFilter = filters.map((fil) => ({ ...fil, isSelected: true }));
      setFilter(tempFilter);
      setAppliedFilters(tempFilter);
      setSelectAll(allValueInKeyOfArrayExists(tempFilter, 'isSelected', true));
    }
  }, [filters]);
  const fetchPeople = () => {
    dispatch(peopleActions.setPeopleRemoved(false));
    fetchMorePeople({
      offset: followedPeople.length,
      filters: filter.filter((item) => item.isSelected),
    });
  };
  const onChangeFilters = (flag: boolean, id: string) => {
    const tempFilters = filter.map((fil) => {
      if (fil.filterId === id) {
        return { ...fil, isSelected: flag };
      } return fil;
    });
    setFilter(tempFilters);
    setSelectAll(allValueInKeyOfArrayExists(tempFilters, 'isSelected', true));
  };
  const selectOrUnselectAll = (flag: boolean) => {
    const tempFilters = filter.map((fil) => ({ ...fil, isSelected: flag }));
    setFilter(tempFilters);
  };

  const onClickHandler = () => {
    selectOrUnselectAll(!selectAll);
    setSelectAll(!selectAll);
  };
  const applyFilterHandler = () => {
    const appliedFilter = filter.filter((item) => item.isSelected);
    dispatch(peopleActions.setAppliedFilters(appliedFilter));
    const params = {
      offset: 0,
      filters: appliedFilter,
    };
    applyFilters(params);
    setAppliedFilters(appliedFilter);

    const filterIds = appliedFilter.filter(
      (item) => item.isSelected,
    ).map((item) => item.filterId).join(',');

    dispatch(helperActions.callFlurryEvent(
      valueToObject('followed_members_filters_applied', 0, { ...params, filters: filterIds }),
    ));
    if (anyValueInKeyOfArrayExists(filter, 'isSelected', false)) {
      setIsFilterActive(true);
    } else {
      setIsFilterActive(false);
    }
    if (followedPeople.length === 0) {
      setIsFilterUnable(true);
    } else {
      setIsFilterUnable(false);
    }
  };

  const editChange = () => {
    if (!editEnable) {
      dispatch(helperActions.callFlurryEvent(
        valueToObject('peopleDashboard_followed_people_edit_clicked', 0, {
          host,
        }),
      ));
    }
    setEditEnable(!editEnable);
  };
  const addRemovePerson = (flag: boolean, id: number) => {
    if (flag) {
      const selectedPersons = selectedPerson.concat(id);
      setSelectedPerson(selectedPersons);
    } else {
      const selectedPersons = selectedPerson.filter((i) => i !== id);
      setSelectedPerson(selectedPersons);
    }
  };
  const showModal = () => {
    setIsModalVisible(true);
    dispatch(helperActions.callFlurryEvent(
      valueToObject('peopleDashboard_followed_people_remove_intent', 0, {
        host,
      }),
    ));
  };
  const handleOk = () => {
    const obj: UpdatePersonProfileFollow = {
      feedids: selectedPerson,
    };
    dispatch(peopleFollowActions.updateProfileFollow(obj));
    dispatch(helperActions.callFlurryEvent(
      valueToObject(`peopleDashboard_followed_people_remove_completed_${selectedPerson.length}_peopleUnfollowed`, 0, {
        host,
      }),
    ));
  };
  useEffect(() => {
    if (isModalVisible) {
      dispatch(groupActions.getMostActiveGroups());
      setIsModalVisible(false);
      setEditEnable(!editEnable);
    }
  }, [followedPeople]);

  useEffect(() => {
    if (url && url.length) {
      const newWin = window.open(url);
      if (newWin) {
        dispatch(helperActions.callFlurryEvent(
          valueToObject('peopleDashboard_followed_members_export_successful', 0, {
            host,
          }),
        ));
        dispatch(helperActions.setNotification({
          message: FILE_DOWNLOAD_SUCCESS,
          proTip: false,
          rightPlacement: false,
          groupId: 0,
          classnameFlag: true,
        }));
      } else {
        dispatch(helperActions.callFlurryEvent(
          valueToObject('peopleDashboard_followed_members_export_failure', 0, { host }),
        ));
        dispatch(helperActions.setNotification({
          message: FILE_DOWNLOAD_FAIL,
          proTip: false,
          rightPlacement: false,
          groupId: 0,
          classnameFlag: false,
        }));
      }
      dispatch(peopleActions.setExportPeopleUrl(''));
    }
  }, [url]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleExport = () => {
    const obj = {
      ...appliedFilters.length > 0 && appliedFilters.length < 16 ? { filters: appliedFilters } : {},
    };

    dispatch(peopleActions.getExportFollowedPeople(obj));
    dispatch(helperActions.callFlurryEvent(
      valueToObject('peopleDashboard_followed_members_export_clicked', 0, {
        host,
      }),
    ));
  };

  const checkForApplyFilter = () => {
    const selectedFilters = filter.filter((item) => item.isSelected);
    return selectedFilters.length === appliedFilters.length
      && selectedFilters.every(
        (item, index) => appliedFilters[index].isSelected === item.isSelected
          && appliedFilters[index].filterId === item.filterId,
      );
  };

  useEffect(() => {
    if (followedPeople?.length !== previousPeople?.length && previousPeople?.length === 20 && peopleRemoved) {
      fetchPeople();
    }
  }, [followedPeople]);

  const isScroll = useSelector(selectIsScroll);
  const isHighlight = useSelector(selectHighlight);
  const currentType = useSelector(selectActivetype);
  const isHighlighted = useSelector(selectIsHighlighted);
  const disclessPersonality = useSelector(selectDisclessPersonality);

  useEffect(() => {
    if (!isHighlighted && !isScroll && currentType === 'person_drag' && !followedLoading) {
      dispatch(certificationActions.setHighlight(true));
      dispatch(certificationActions.setIsHighlighted(false));
    }
  }, [isScroll, currentType, followedLoading]);

  return (
    <>
      <ActionWrapper className="wrapper-head d-flex justify-content-between smMob-wrapper" id="tabs-details">
        <PopupModal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} centered cancelText="No" okText="Yes" closeIcon={<CrossCircle />} width="530px">
          <p className="main-message">Are you sure you want to unfollow the selected people?</p>
        </PopupModal>
        <Heading>
          People I follow
          <span className={`ml-c-5 ${translateSkipAreas}`}>{` (${formattedPeopleCount})`}</span>
          <HeadingWithToolTip
            heightIconXSm="17px"
            toolTipText={TOOLTIP_PEOPLEIFOLLOW}
          />
        </Heading>
        {(peopleCount > 0 || (peopleCount === 0 && (anyValueInKeyOfArrayExists(filter, 'isSelected', false)))) && (
          <div className="other-side staff-btn-style d-flex align-items-center">
            {!editEnable
              ? (
                <span
                  className="tabs-item svg-style"
                  onClick={editChange}
                  onKeyPress={editChange}
                  tabIndex={0}
                  role="button"
                  aria-label="Edit"
                >
                  <PencilIcon />
                  {!isTab && (
                    <span>
                      Edit
                    </span>
                  )}
                </span>
              )
              : (
                <>
                  <span
                    className={`tabs-item svg-style remove-space  ${selectedPerson.length < 1 ? 'disabled' : ''}`}
                    onClick={showModal}
                    onKeyPress={showModal}
                    tabIndex={0}
                    role="button"
                    aria-label="Remove"
                  >
                    <DeleteIcon />
                    {!isTab && (
                      <span>
                        Remove
                      </span>
                    )}
                  </span>
                  <span
                    className="tabs-item svg-style cancel-space icon-mode-cancel"
                    onClick={editChange}
                    onKeyPress={editChange}
                    tabIndex={0}
                    role="button"
                    aria-label="Cancel"
                  >
                    {isTab ? (
                      <Cross />
                    ) : (
                      <span>Cancel</span>
                    )}
                  </span>
                </>

              )}
            {
              disclessPersonality ? null : (
                <div className="filter-people-dashboard">
                  <FilterPopOver
                    loading={followedLoading}
                    filters={filter}
                    disableApplyBtn={checkForApplyFilter()}
                    selectAll={selectAll}
                    onChangeFilters={onChangeFilters}
                    onClickHandler={onClickHandler}
                    applyFilterHandler={applyFilterHandler}
                    iconMode={isTab}
                    isActive={isFilterActive}
                  />
                </div>
              )
            }
            <div className="export-people">
              <GlobalButton
                title="Export"
                textColor="#7E7E7E"
                bgColor="#fff"
                outlineColor="#DADADA"
                innerSpace="6px 15px 6px 15px"
                LeftSpace="7px"
                IconLeft={<ExportIcon />}
                buttonClassName="share-button mr-24 export_btn"
                rightSeparatorClassName="right-separator-class"
                onButtonClick={handleExport}
                disabled={exportFollowLoading || editEnable || !followedPeople.length}
              />
            </div>

          </div>
        )}

      </ActionWrapper>
      {
        followedLoading ? <PeoplesSkeleton />
          : followedPeople.length === 0 && !followedLoading
            ? (
              <NoPersonsFound
                peopleNotInFilter
                title={isFilterUnable
                  ? 'Seems like you have not added people to your network. \nPlease search for people to add them to your network'
                  : 'No person available based on this filter. Please chose a different option'}
              />
            ) : (
              <InfiniteScroll
                hasMore
                dataLength={followedPeople?.length ?? 0}
                next={fetchPeople}
                className="o-x-hidden o-y-hidden"
                loader={moreFollowedLoading && peopleCount !== followedPeople.length
                  && !ended && <PeoplesSkeleton />}
              >
                <Row className="Grid-width-row person-i-follow-negative" gutter={[24, 24]} style={{ margin: '0' }}>
                  {editEnable
                    ? followedPeople.map(
                      (people: Executive) => (
                        <Col xxl={{ span: 4 }} xl={{ span: 4 }} lg={{ span: 6 }} className="gutter-row">
                          <PeopleCardLarge
                            key={people?.personId}
                            showDrawerIcon={!!people?.personNotes}
                            persona={people?.archeType?.color}
                            logo={people?.personImage}
                            personName={people?.personName}
                            tag={people?.personDesignation}
                            personaType={people?.archeType?.name}
                            wrapperColor={backgroundColorParsing(people?.personalityTypes)}
                            personalityTypesColors={people?.personalityTypes}
                            hidePersonality={false}
                            follow={false}
                            showCheckBox={editEnable}
                            checked={!!selectedPerson.find((person) => person === people.personId)}
                            personId={people?.personId}
                            addRemovePerson={addRemovePerson}
                            company={people?.companyName}
                          />
                        </Col>
                      ),
                    ) : followedPeople.map(
                      (people: Executive, index) => (
                        <Col xxl={{ span: 4 }} xl={{ span: 4 }} lg={{ span: 6 }} className="gutter-row">
                          <DragWrapper
                            key={people?.personId}
                            isDrag={isDrag}
                            highlighted={index === 0 && isHighlight && currentType === 'person_drag'}
                          >
                            <PeopleCardLarge
                              key={people?.personId}
                              showDrawerIcon={!!people?.personNotes}
                              persona={people?.archeType?.color}
                              logo={people?.personImage}
                              personName={people?.personName}
                              tag={people?.personDesignation}
                              personaType={people?.archeType?.name}
                              wrapperColor={backgroundColorParsing(people?.personalityTypes)}
                              personalityTypesColors={people?.personalityTypes}
                              hidePersonality={false}
                              follow={false}
                              showCheckBox={editEnable}
                              checked={!!selectedPerson.find((person) => person === people.personId)}
                              personId={people?.personId}
                              addRemovePerson={addRemovePerson}
                              company={people?.companyName}
                            />
                          </DragWrapper>
                        </Col>
                      ),
                    )}
                </Row>
              </InfiniteScroll>
            )
      }
    </>
  );
};

export default PeopleIFollow;
