/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import axios from 'Utils/Axios';
import { API } from 'Utils/Constants';
import { actions } from 'Slice/GlobalSearchContentSlice';
import { DeviceTokenResponse, GlobalSearchContentResponse, SaveDeviceTokenResponse } from 'Types/GlobalSearchContentTypes';
import { PayloadAction } from '@reduxjs/toolkit';
import { setLocalStorageValue } from 'Utils/UtilityFunctions';
import { GlobalResponse } from 'Types/ResponseBodies';
import { ComplianceAckRequest } from 'Types/RequestBodies';

export function* globalSearchContent() {
  try {
    const response: GlobalSearchContentResponse = yield call(
      axios.post,
      API.GLOBAL_SEARCH_CONTENT,
      {},
    );
    if (response?.status === true) {
      const contents = response?.response;
      yield put(actions.globalSearchContentLoaded(contents));
    } else {
      yield put(actions.globalSearchContentError(response?.message));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getDeviceTokenSaved(dispatched: PayloadAction<DeviceTokenResponse>) {
  try {
    const { payload } = dispatched;
    const response: SaveDeviceTokenResponse = yield call(
      axios.post,
      API.SAVE_WEB_DEVICE_TOKEN,
      payload,
    );
    if (response?.status === true) {
      const contents: DeviceTokenResponse = response?.response;
      yield put(actions.setDeviceTokenSaved(contents));
      setLocalStorageValue('onesignalcall', 'true');
    }
  } catch (error) {
    console.log(error);
  }
}

export function* complianceAckCall(dispatched: PayloadAction<ComplianceAckRequest>) {
  try {
    const response: GlobalResponse = yield call(
      axios.post,
      API.COMPLIANCE_ACKNOWLEDGEMENT,
      dispatched.payload,
    );
    if (response?.status === true) {
      yield put(actions.complianceAckCallSuccess());
    } else {
      yield put(actions.complianceAckCallFail());
    }
  } catch (error) {
    yield put(actions.complianceAckCallFail());
    console.log(error);
  }
}

export function* updateGilroyTooltip() {
  try {
    const response: GlobalResponse = yield call(
      axios.post,
      API.UPDATE_GILROY_TOOLTIP,
      { gilroyPopup: true },
    );
    if (response?.status === true) {
      yield put(actions.setUpdateGilroyTooltip());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* useGlobalSearchContentSaga() {
  yield takeLatest(actions.globalSearchContent.type, globalSearchContent);
  yield takeLatest(actions.getDeviceTokenSaved.type, getDeviceTokenSaved);
  yield takeLatest(actions.complianceAckCalling.type, complianceAckCall);
  yield takeLatest(actions.updateGilroyTooltip.type, updateGilroyTooltip);
}
