import React, { useEffect, useState } from 'react';
import {
  Collapse,
} from 'antd';
import CheckedIcon from 'Components/alerts/assets/svg/CheckedIcon';
import CrossCircle from 'Components/common/assets/svgIcons/CrossCircle';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveTask, selectActivetype, selectHighlight, selectIsChatLoading, selectShowAccordion,
} from 'Selector/CertificationSelector';
import classNames from 'classnames';
import { isShowGilroyGlobal } from 'Selector/UISelector';
import { CertificationTask } from 'Types/CertificationTypes';
import { actions as UiActions } from 'Slice/UISlice';
import { actions as helperActions } from 'Slice/HelperSlice';
import { actions as certificationActions } from 'Slice/CertificationSlice';
import styled from 'styled-components';
import { selectAccountPlanOpen } from 'Selector/CompaniesSelector';
import { certificationBot } from '../../../Utils/utilsStyle';
import { CustomCaretDownOutlined } from './CustomizedPanel';
import { actions } from '../../../Slice/CertificationSlice';
import PromptCopyCmp from './PropmtCopyCmp';
import { chatActions } from 'Components/layout/headers/NavBarPrimary';

const defaultProps = {
  className: '',
};

type Props = {
  className?: string;
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  .certification-collapse-bottom{
    .ant-collapse-item{
      .ant-collapse-header{
        .ant-collapse-header-text{
          .accordion-header{
            .header-image{
              margin-bottom: 8px;
            }
         } 
        }
      }
    }
    &.ant-collapse-item-active{
      .ant-collapse-header{
        .ant-collapse-header-text{
          .accordion-header{
            .header-image{
              margin-bottom: 0px;
            }
         } 
        }
      }
    }
  } 

`;

const BottomAccordion = function BottomAccordion(props: Props) {
  const { className } = props;
  const { Panel } = Collapse;
  const chatLoading = useSelector(selectIsChatLoading);
  const visible = useSelector(selectAccountPlanOpen);
  const task = useSelector(selectActiveTask);
  const show = useSelector(selectShowAccordion);
  const isGilroyOpen = useSelector(isShowGilroyGlobal);
  const currentType = useSelector(selectActivetype);
  const isHighlight = useSelector(selectHighlight);
  const [activeKey, setActiveKey] = useState<string | string[]>([task?.hintPrompt ? '1' : '']);
  const nodeCertification = document.querySelector('.certification-collapse-bottom');
  const dispatch = useDispatch();
  const closeAccordion = () => {
    dispatch(actions.resetActiveTask());
    dispatch(actions.resetCurrentType());
    dispatch(actions.setShowAccordion(false));
  };

  const onChange = (keys: string | string[]) => {
    setActiveKey(keys);
  };

  useEffect(() => {
    if (task?.hintPrompt) {
      setActiveKey('1');
    } else {
      setActiveKey('');
    }
  }, [task]);

  const handlePromptClick = (stask: CertificationTask) => {
    console.log('here click', chatLoading);
    if (chatLoading) {
      if (!isGilroyOpen) {
        dispatch(UiActions.isShowGilroyGlobalToggle(true));
      }
      dispatch(helperActions.setNotification({
        message: 'Your previous prompt is being generated. Please wait.',
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: false,
        getContainerEle: 'chatContainer',
      }));
    } else if (isGilroyOpen) {
      chatActions.setCertificationPrompt(stask?.hintPrompt || '');
      dispatch(actions.setOpenDrawer(false));
    } else {
      dispatch(UiActions.isShowGilroyGlobalToggle(true));
      chatActions.setCertificationPrompt(stask?.hintPrompt || '');
      dispatch(actions.setOpenDrawer(false));
      if (isHighlight && currentType === 'document_hub_panel') {
        dispatch(certificationActions.setHighlight(false));
        dispatch(certificationActions.setIsHighlighted(true));
      }
    }
  };

  useEffect(() => {
    if (visible) {
      if (nodeCertification) {
        nodeCertification.classList.add('certification-collapse-width');
        // apply style here //width, left, transform
      }
    } else if (nodeCertification) {
      nodeCertification.classList.remove('certification-collapse-width');
    }
  }, [visible]);

  return (
    <Wrapper className={className}>
      {
        show ? (
          <Collapse
            className="certification-collapse-bottom"
            expandIcon={({ isActive }) => CustomCaretDownOutlined({ isActive })}
            ghost
            accordion
            activeKey={activeKey}
            onChange={onChange}
          >
            <span
              tabIndex={0}
              role="button"
              onClick={() => closeAccordion()}
              onKeyPress={() => closeAccordion()}
              className="cross-circle-accordion"
              aria-label="text"
            >
              <CrossCircle />
            </span>
            <Panel
              header={(
                <span className="accordion-header">
                  <div>
                    <img
                      className="header-image"
                      alt="gilroy"
                      src={certificationBot}
                    />
                    {task?.header}
                  </div>

                </span>
              )}
              key="1"
            >
              <div className="sub-component-div">
                {task?.events?.map((event, index) => (
                  <>
                    <span className="panel-sub-component">
                      <span className={classNames({ primaryColorGlobal: event.status === 1 })}>
                        {task?.hintPrompt?.length
                          ? (
                            <>
                              <span className="index">
                                {index + 1}
                                .
                              </span>
                              {' '}
                              {event.title.slice(3)}
                            </>
                          ) : event.title}
                      </span>
                      {event.status === 1 ? <CheckedIcon /> : null}
                    </span>
                    {task.hintPrompt?.length && index === task.events.length - 1 ? (
                      <PromptCopyCmp
                        eventType={event.type}
                        promptTitle={task.hintPrompt}
                        source={task?.source}
                        onClick={() => handlePromptClick(task)}
                      />
                    ) : null}
                  </>
                ))}
              </div>
            </Panel>
          </Collapse>
        ) : null
      }
    </Wrapper>
  );
};
BottomAccordion.defaultProps = defaultProps;
export default BottomAccordion;
