import { Badge, Drawer, Tooltip } from 'antd';
import CloseAlerts from 'Components/alerts/assets/svg/CloseAlerts';
import MessageIcon from 'Components/common/assets/svgIcons/MessageIcon';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  leader, minWidthApp,
  maxWidth, primaryHeadingColor, retina,
  secondaryFont, wMBold, maxWidth1170, maxTablet, primaryColor,
} from 'Utils/utilsStyle';
import { useDispatch, useSelector } from 'react-redux';
import { actions as personProfileSlice } from 'Slice/PersonProfileSlice';
import { actions as notesSlice } from 'Slice/NotesSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
import SkeletonCustom from 'Components/common/skeleton/SkeletonCustom';
import GlobalButton from 'Components/common/components/GlobalButton';
import PenIcon from 'Components/common/assets/svgIcons/PenIcon';
import classNames from 'classnames';
import {
  selectNewNotes,
  selectPersonNotesAvaliable,
} from 'Selector/PersonProfileSelector';
import {
  Executive,
} from 'Types/PersonTypes';
import PeopleWidgetWithOutHolo from 'Components/common/components/PeopleWidgetWithOutHolo';
import {
  getLocalStorageValue,
  IsTablet,
  replaceURLWithoutReload,
  valueToObject,
} from 'Utils/UtilityFunctions';
import { actions as certificationActions } from 'Slice/CertificationSlice';
import { selectData, selectOpenDrawer } from 'Selector/CertificationSelector';
import {
  selectAlertNotificationId,
  selectNoteAdded, selectNoteDeleted, selectNoteEdited, selectPersonNotes,
} from 'Selector/NotesSelector';
import { EntityNotes } from 'Types/NotesTypes';
import { GROUPDETAILS } from 'Utils/Constants';
import { selectCompanyNotesAvaliable, selectCompanyNewNotes } from 'Selector/CompaniesSelector';
import { actions as companyActions } from 'Slice/CompaniesSlice';
import { actions as flurryActions } from 'Slice/HelperSlice';
import PeopleMoveReasonCard from './PeopleMoveReasonCard';

const Heading = styled.h3`
  font-family: ${secondaryFont};
  font-size: 46px;
  line-height: 44px;
  color: ${primaryHeadingColor};
  font-weight: ${wMBold};
  margin-bottom: 0px;
  padding: 45px 30px 48px 30px;
  border-bottom: 2px solid #EBEBEB;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    padding: 34px 30px 34px 30px;
    font-size: 32px;
    line-height: 30px;
  }
  &.mb-head {
    margin-bottom: 30px;
  }
`;
const Wrapper = styled.div`
  cursor: pointer;
  .triggers-bullet-tooltip{
    .ant-tooltip{
      z-index: 1;
      @media (max-width: ${maxTablet}) {
        display: none;
      }
    }  
  }
  .drawerAction{
    position: absolute;
    right: 5px;
    top: 0px;
    cursor: pointer;
    @media all and (max-width: ${maxWidth1170}) {
      right: -2px;
      top: 0px;
    }
  }
  .messageIcon {
    height: 26px;
    width: 26px;
    border-radius: 50%;
    background-color: ${primaryColor};
    position: relative;
    svg {
      position: absolute;
      left: 7px;
      top: 7px;
    }
    @media all and (max-width: ${maxWidth1170}) {
      height: 22px;
      width: 22px;
       svg {
        left: 7px;
        width: 8px;
        top: 5px;
      }
    }
  }
  .ant-badge-dot {
    width: 14px;
    height: 14px;
    background-color: ${leader};
    right: 9px;
    border: 2px solid #fff;
    top: 2px;
  }
  button{
    font-weight: ${wMBold};
    svg{
      margin-bottom: 2px;
      margin-right: 8px;
    }
  }
  .drawer-btn-action {
    &.notAvailable {
      .btn-notes-drawer {
        @media all and (max-width: ${minWidthApp}) {
          display: none;
        }
      }
    }
  }
`;
const SkeletonWrapper = styled.div`
  padding: 15px 30px;
`;

type Props = {
  personId?: number,
  companyId?: number,
  isPeopleNotes?: boolean,
  isCompanyNotes?: boolean,
  isPersonCard?: boolean,
  showNotes?: boolean,
} & typeof defaultProps;

const defaultProps = {
  personId: 0,
  companyId: 0,
  isCompanyNotes: false,
  isPeopleNotes: false,
  showNotes: false,
  isPersonCard: false,
};

const DrawerPeopleMoveReason = function DrawerPeopleMoveReason(props: Props) {
  const {
    personId, isPeopleNotes, showNotes, companyId, isCompanyNotes, isPersonCard,
  } = props;
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [toggle, setToggle] = useState(false);
  const [addNote, setAddNote] = useState(true);
  const [noteId, setNoteId] = useState(0);
  const isTab = IsTablet();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get('groupId');
  const isNotes = searchParams.get('isNotes') === 'true';
  const groupIdParsed = groupId ? parseInt(groupId, 10) : 0;
  const personNotes = useSelector(selectPersonNotes);
  const {
    notes, notesLoading, notesEnded, notesPageNumber,
  } = personNotes;

  const [personYou, setPersonYou] = useState<Executive>();
  const [newNote, setNewNote] = useState<EntityNotes>();
  const [isGroupDetail] = useState(pathname.includes(GROUPDETAILS));
  const [hasScrolled, setHasScrolled] = useState(false);

  const noteDeleted = useSelector(selectNoteDeleted);
  const noteEdited = useSelector(selectNoteEdited);
  const noteAdded = useSelector(selectNoteAdded);

  const personNotesAvaliable = useSelector(selectPersonNotesAvaliable);
  const personNewNotes = useSelector(selectNewNotes);
  const companyNotesAvaliable = useSelector(selectCompanyNotesAvaliable);
  const companyNewNotes = useSelector(selectCompanyNewNotes);

  const data = useSelector(selectData);
  const certificationDrawer = useSelector(selectOpenDrawer);
  const alertsNotificationId = useSelector(selectAlertNotificationId);

  const notesAvailable = isPeopleNotes ? personNotesAvaliable : companyNotesAvaliable;

  const clickToggle = () => {
    if (toggle) {
      setHasScrolled(false);
      dispatch(notesSlice.setAlertsNotificationId(0));
    }
    if (personYou) {
      setToggle(!toggle);
      dispatch(notesSlice.resetPersonNotes());
    }
    if (isPeopleNotes) {
      dispatch(certificationActions.certificationAction({
        type: 'person_note_write',
        source: 'person',
        data,
      }));
    }
  };
  useEffect(() => {
    const node = document.querySelector('body');
    return toggle ? node?.classList.add('body-drawer-toggle-account-plan') : node?.classList.remove('body-drawer-toggle-account-plan');
  }, [toggle]);
  const fetchMoreNotes = () => {
    if (notes?.length && !notesLoading && personYou) {
      dispatch(notesSlice.getPersonNotes({
        ...(isGroupDetail && { groupId: groupIdParsed }),
        ...((isPeopleNotes || isPersonCard) && { personId }),
        ...(isCompanyNotes && { companyId }),
        page: notesPageNumber,
        type: isGroupDetail ? 'reason' : 'notes',
      }));
    }
  };

  useEffect(() => {
    if (alertsNotificationId > 0 && !notesLoading && notes?.length > 0 && !hasScrolled) {
      const element = document.getElementById(`scroll-to-${alertsNotificationId}`);
      const scrollElement = document.querySelector('.data-append-wrapper') as HTMLElement;
      const isPresent = notes.filter(
        (note) => note.comment.noteId === alertsNotificationId,
      ).length > 0;
      if (element && isPresent) {
        setHasScrolled(true);
        setTimeout(() => {
          scrollElement.scrollTo({
            top: element.offsetTop - 170,
            behavior: 'smooth',
          });
          element.classList.add('highlight');
        }, 500);
        setTimeout(() => {
          element.classList.remove('highlight');
        }, 20000);
      } else if (!element && !isPresent) {
        setHasScrolled(true);
        const notification = {
          message: 'Selected Note not found',
          proTip: false,
          rightPlacement: false,
          groupId: 0,
          classnameFlag: false,
        };
        dispatch(flurryActions.setNotification(notification));
      }
    }
  }, [notesLoading]);

  const getReasonSkeleton = () => (
    <>
      {[1, 2].map(() => (
        <SkeletonWrapper>
          <SkeletonCustom width="31px" height="31px" diameter="50%" marginSm="0px 9px 0px 0px" />
          <SkeletonCustom width="30%" height="20px" marginSm="0px 0px 5px 0px" />
          <br />
          <SkeletonCustom height="100px" margin="0px 0px 5px 0px" marginSm="0px 0px 5px 0px" />
        </SkeletonWrapper>
      ))}
    </>
  );

  const handleNewNote = () => {
    if (!addNote) setNoteId(0);
    setAddNote(!addNote);
  };

  const noteIdHandler = (id?: number) => {
    setNoteId(id || 0);
  };

  useEffect(() => {
    if (certificationDrawer && toggle) {
      setToggle(false);
    }
  }, [certificationDrawer]);

  useEffect(() => {
    if (toggle) {
      if ((isPeopleNotes || isCompanyNotes || isNotes || showNotes)
        && !isGroupDetail && personYou) {
        dispatch(notesSlice.getPersonNotes({
          page: 1,
          ...(!isCompanyNotes && { personId }),
          ...(isCompanyNotes && { companyId }),
          type: 'notes',
        }));
      } else if (isGroupDetail) {
        dispatch(notesSlice.getPersonNotes({
          groupId: groupIdParsed,
          personId,
          page: 1,
          type: 'reason',
        }));
      }
    }
  }, [toggle]);

  useEffect(() => {
    if (isNotes && personYou && alertsNotificationId > 0) {
      setToggle(true);
      replaceURLWithoutReload('isNotes', 'false');
    }
  }, [isNotes, personYou, alertsNotificationId]);

  useEffect(() => {
    if (noteAdded || noteDeleted || noteEdited) {
      dispatch(notesSlice.addPersonNoteFailed());
      dispatch(notesSlice.deletePersonNoteFailed());
      dispatch(notesSlice.editPersonNoteFailed());
    }
  }, [noteAdded, noteDeleted, noteEdited]);

  useEffect(() => {
    if (toggle) {
      if (notesAvailable || isGroupDetail) setAddNote(false);
      else if (!isGroupDetail && !showNotes) setAddNote(true);
      else setAddNote(false);
    }
  }, [notesAvailable, toggle]);

  useEffect(() => {
    if (noteDeleted || noteAdded) {
      if (isCompanyNotes) {
        dispatch(companyActions.setCompanyNotesAvailable(notes.length ? 1 : 0));
      } else {
        dispatch(personProfileSlice.setPersonNotesAvailable(notes.length ? 1 : 0));
      }
    }
  }, [personNotes]);

  useEffect(() => {
    setToggle(false);
  }, [personId, companyId]);

  useEffect(() => {
    if (personYou) {
      setNewNote({
        comment: {
          person: personYou,
          text: '',
          type: '',
          dateTimeStamp: '',
          editable: false,
          noteId: 0,
          changed: false,
          entities: {},
        },
      });
    } else {
      setPersonYou({
        personName: getLocalStorageValue('fullname') || '',
        personImage: getLocalStorageValue('profilePic') || '',
        companyName: getLocalStorageValue('personCompany') || '',
        personDesignation: getLocalStorageValue('personDesignation') || '',
        companyId: 0,
        companyLogo: '',
        executiveType: '',
        headline: '',
        personId: 0,
        archeType: {
          personality: '',
          color: '',
          name: '',
          image: '',
        },
        personAdded: false,
        personalityTypes: [],
        feedid: 0,
      });
    }
  }, [personYou]);

  const handleFlurry = () => {
    if (!toggle && isCompanyNotes) {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('companyNotes_open_clicked', 0, {
          companyId,
        }),
      ));
    }
  };

  return (
    <Wrapper>
      <span
        onClick={clickToggle}
        onKeyPress={clickToggle}
        tabIndex={0}
        role="button"
        aria-label="drawer-open"
        className={classNames('drawer-btn-action', {
          drawerAction: !isPeopleNotes && !isCompanyNotes,
          notAvailable: !notesAvailable,
        })}
      >
        {!showNotes ? (
          <Tooltip
            placement="bottom"
            getPopupContainer={(e: HTMLElement) => e}
            className="triggers-bullet-tooltip space-remove"
            color="#fff"
            title="Add a Note to Profile"
          >
            <Badge dot={isPeopleNotes ? !!personNewNotes : !!companyNewNotes}>
              <GlobalButton
                title={`${notesAvailable ? 'Notes' : 'Write a note'}`}
                textColor="#fff"
                bgColor={primaryColor}
                PaddingSm="8px 16px 8px"
                innerSpace="13px 20px 13px 20px"
                heightSm="35.6px"
                LeftSpace="12px"
                borderRadius="23px"
                IconLeft={isTab ? <span /> : <PenIcon />}
                onButtonClick={() => {
                  handleFlurry();
                  clickToggle();
                }}
                disabled={false}
                buttonClassName="btn-notes-drawer"
              />
            </Badge>
          </Tooltip>
        ) : (
          <Tooltip
            placement="top"
            getPopupContainer={(e: HTMLElement) => e}
            className="triggers-bullet-tooltip smartHistoryTip"
            color="#fff"
            title="View notes added"
          >
            <div className="messageIcon">
              <MessageIcon color="#fff" />
            </div>
          </Tooltip>
        )}
      </span>
      <Drawer
        className="drawer-alerts reason-drawer"
        placement="right"
        onClose={clickToggle}
        visible={toggle}
        destroyOnClose
        width={530}
      >
        <span
          onClick={clickToggle}
          onKeyPress={clickToggle}
          tabIndex={0}
          role="button"
          aria-label="close"
          className="close-alerts reason-drawer-close"
        >
          <CloseAlerts />
        </span>
        <Heading>{!isGroupDetail ? 'Notes' : 'Comments'}</Heading>
        {!addNote && personYou
          ? (
            <div
              className="add-note"
              onClick={handleNewNote}
              onKeyPress={handleNewNote}
              role="button"
              tabIndex={0}
            >
              <PeopleWidgetWithOutHolo
                diameter="31px"
                diameterSm="31px"
                diameterXsm="31px"
                logo={personYou?.personImage || ''}
                wrapperColor={getLocalStorageValue('personalitycolors') || ''}
                margin="0"
                ringPadding="2px"
                ringPaddingSm="2px"
              />
              <span
                className="add-note-input"
              >
                Add a note...
              </span>
            </div>
          ) : ''}
        {addNote && newNote && !isTab
          ? (
            <PeopleMoveReasonCard
              notes={newNote}
              isNewNoteOpen={addNote}
              newNote
              noteAbout={isCompanyNotes ? companyId : personId}
              isPeopleNotes={!isCompanyNotes}
              isCompanyNotes={isCompanyNotes}
              noEdit
              handleNewNoteCancel={handleNewNote}
            />
          ) : ''}
        <div className="h-100 unique-reason-class">
          <InfiniteScroll
            dataLength={notes.length}
            next={fetchMoreNotes}
            hasMore={!notesEnded}
            loader={false}
            className="data-append-wrapper"
            height="auto"
          >
            {notes?.length && personYou ? notes?.map((note: EntityNotes) => (
              <PeopleMoveReasonCard
                key={note.comment?.noteId}
                isPeopleNotes={!isCompanyNotes}
                isCompanyNotes={isCompanyNotes}
                noteAbout={isCompanyNotes ? companyId : personId}
                isNewNoteOpen={addNote}
                notes={note}
                handleNewNoteCancel={handleNewNote}
                getNoteId={noteIdHandler}
                editNoteId={noteId}
              />
            ))
              : null}
            {(notesLoading) && getReasonSkeleton()}
          </InfiniteScroll>
        </div>

      </Drawer>
    </Wrapper>

  );
};

DrawerPeopleMoveReason.defaultProps = defaultProps;
export default DrawerPeopleMoveReason;
