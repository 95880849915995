/* eslint-disable no-nested-ternary */
import DrawerAlerts from 'Components/alerts/components/DrawerAlerts';
import BackIcon from 'Components/common/assets/svgIcons/BackIcon';
import AccountPopOver from 'Components/common/components/AccountPopOver';
import GlobalButton from 'Components/common/components/GlobalButton';
import SkeletonCustom from 'Components/common/skeleton/SkeletonCustom';
import GlobalSearch from 'Components/globalSearch/components/GlobalSearch';
import SectionHeading from 'Components/globalSearch/components/SectionHeading';
import NavBarPrimary from 'Components/layout/headers/NavBarPrimary';
import ExportIcon from 'Components/common/assets/svgIcons/ExportIcon';
import PeopleCardLarge from 'Components/common/components/PeopleCardLarge';
import {
  IsMobile, IsTablet, backgroundColorParsing,
  formatNumberWithCommas, isGuest, isSalesforce, valueToObject,
} from 'Utils/UtilityFunctions';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import HeadingWithToolTip from 'Components/common/components/HeadingWithToolTip';
import {
  maxMobile, maxTablet, maxWidth, retina,
} from 'Utils/utilsStyle';
import { SimilarPeopleData, SimilarPeopleFilters } from 'Types/PersonTypes';
import { Col, Row } from 'antd';
import DragWrapper from 'Components/people/components/DragWrapper';
import DrawerPeopleGroup from 'Components/people/components/DrawerPeopleGroup';
import { useDispatch, useSelector } from 'react-redux';
import { actions as groupActions } from 'Slice/GroupsSlice';
import { PeoplesSkeleton } from 'Components/people/components/PeopleIFollow';
import FilterSimilarPeople from 'Components/common/components/FilterSimilarPeople';
import {
  selectExportSimilarPeopleLoading,
  selectSimilarPeopleData, selectSimilarPeopleFilters,
} from 'Selector/PersonProfileSelector';
import { actions } from 'Slice/PersonProfileSlice';
import { actions as helperActions } from 'Slice/HelperSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoPersonsFound from 'Components/common/components/NoPersonsFound';
import { TOOLTIP_SIMILAR_PEOPLE } from 'Utils/Constants';
import Empty from 'Components/common/assets/svgIcons/Empty';
import { selectShowChatbot, selectShowGlobalGilroy } from 'Selector/GlobalSearchContentSelector';
import { Gilroy } from 'gilroy-global';
import { useGilroy } from 'Utils/hooks/useGilroy';

const Wrapper = styled.div`
  .boxDoor {
    @media all and (max-width: ${maxMobile}){ 
      top: 180px; 
    }
  }
  .similar-people-header{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media all and (max-width: ${maxMobile}){ 
      flex-direction: column;
    }
  }
  .right-div{
    display: flex;
    align-items: center;
    @media all and (max-width: ${maxMobile}){ 
      width: 100%;
      justify-content: flex-end;
      margin-top: 10px;
    }
  }
  .left-div{
    display: flex;
    align-items: center;
    @media all and (max-width: ${maxMobile}){ 
      width: 100%;
      justify-content: flex-start;
    }
    .similar-people-tooltip{}
  }
  .export-similar-people {
    .export_btn {
      min-width: 105px;
      @media all and (max-width: ${maxWidth}), ${retina} {
        min-width: 90px;
      }
      svg {
        width: 17px;
        min-width: 17px;
        margin-right: 10px;
        @media all and (max-width: ${maxWidth}), ${retina} {
          width: 12px;
          min-width: 12px;
        }
      }
    }
    @media all and (max-width: ${maxTablet}){ 
    .export_btn {
      width: 31px;
      height: 28px;
      padding: 0px 9px;
      svg {
        width: 14px;
        min-width: 14px;
      }
    }
    }
  } 
  .filter-similar-people {
    margin-right: 5px;
  }
`;

const SimilarPeople = function SimilarPeople() {
  const isTab = IsTablet();
  const isMob = IsMobile();
  const { gilroyStates, gilroyActions } = useGilroy();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const paramId = searchParams.get('personId');
  const personType = searchParams.get('personType');
  const personId = paramId ? parseInt(paramId, 10) : 0;
  const [dragging, setDragging] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const exportLoading: boolean = useSelector(selectExportSimilarPeopleLoading);
  const similarPeopleData: SimilarPeopleData = useSelector(selectSimilarPeopleData);
  const similarPeopleFilters: SimilarPeopleFilters = useSelector(selectSimilarPeopleFilters);
  const allowGilroyGlobalFlag = useSelector(selectShowGlobalGilroy);
  const chatbotAllowed = useSelector(selectShowChatbot);

  const {
    people, loading, title, count, page, loaded,
    ended, name,
  } = similarPeopleData;

  const {
    filters,
  } = similarPeopleFilters;
  const formattedScoreCount = formatNumberWithCommas(count);

  const titleWithCount = `${title} (${formattedScoreCount})`;

  const fetchSimilarPeople = () => {
    if (!loading) {
      const filtersObj = filters.filter((filter) => filter.isSelected);
      dispatch(actions.getSimilarPeople({
        personId,
        ...(personType && { type: personType }),
        ...(filtersObj.length > 0 && filtersObj.length !== filters.length && { companyIds: filtersObj.map((obj) => obj.companyId).join(',') }),
        page,
      }));
    }
  };

  useEffect(() => {
    fetchSimilarPeople();
  }, []);

  useEffect(() => {
    setShowNew(false);
    setOpenCreateModal(dragging);
  }, [dragging]);

  const toggle = (flag: boolean) => {
    setOpenCreateModal(flag);
  };

  const isDrag = (a: boolean) => {
    if (a) dispatch(groupActions.setDropComplete(false));
    setDragging(a);
  };

  const handleExport = () => {
    dispatch(helperActions.callFlurryEvent(
      valueToObject('similar_people_export_click', 0),
    ));
    const filtersObj = filters.filter((filter) => filter.isSelected);
    dispatch(actions.getSimilarPeopleExport({
      personId,
      ...(personType && { type: personType }),
      ...(filtersObj.length > 0 && { companyIds: filtersObj.map((obj) => obj.companyId).join(',') }),
    }));
  };

  return (
    <Wrapper className="similar-people-wrapper">
      <NavBarPrimary headerClassName="no-overflow-on-header">
        <div className="left-side-wrap">
          <BackIcon onClick={() => { navigate(-1); }} />
          {!loaded ? (
            <SkeletonCustom
              width="340px"
              smWidth="150px"
              height="30px"
              margin="0px 0px 0px 5px"
            />
          ) : (
            <h1 className="heading text-truncate-two-line">
              {name}
            </h1>
          )}
        </div>
        <div className="right-side-wrap">
          {!isSalesforce() && <GlobalSearch iconMode={isTab} />}
          <DrawerAlerts />
          <AccountPopOver />
        </div>
      </NavBarPrimary>
      <div className="container">
        {!isGuest() && (
          <DrawerPeopleGroup
            openNewCreate={openCreateModal}
            setOpenNew={toggle}
            showNew={showNew}
            toggleShowNew={(bool: boolean) => setShowNew(bool)}
            noButton
            drag={dragging}
          />
        )}
        <div className="similar-people-header">
          {!loaded ? <SkeletonCustom width={isMob ? '200px' : '340px'} height="30px" margin="0px" /> : (
            <>
              <div className="left-div">
                <div className="text-truncate">
                  <SectionHeading customClassName="similar-people-heading">{titleWithCount}</SectionHeading>
                </div>
                <div className="similar-people-tooltip d-flex align-items-center">
                  <HeadingWithToolTip
                    toolTipText={TOOLTIP_SIMILAR_PEOPLE}
                    midTabletFlag
                    heightIcon="20px"
                    heightIconSm="17px"
                    heightIconXSm="18px"
                  />
                </div>
              </div>
              {
                people.length ? (
                  <div className="right-div">
                    {/* {
                      !disclessPersonality ? ( */}
                    <div className="filter-similar-people">
                      <FilterSimilarPeople personId={personId} filters={filters} />
                    </div>
                    {/* ) : null
                    } */}
                    <div className="export-similar-people">
                      <GlobalButton
                        title="Export"
                        textColor="#7E7E7E"
                        bgColor="#fff"
                        outlineColor="#DADADA"
                        innerSpace="6px 15px 6px 15px"
                        LeftSpace="7px"
                        IconLeft={exportLoading ? <Empty /> : <ExportIcon />}
                        buttonClassName="share-button mr-24 export_btn"
                        rightSeparatorClassName="right-separator-class"
                        onButtonClick={handleExport}
                        disabled={exportLoading}
                        loading={exportLoading}
                      />
                    </div>
                  </div>
                ) : null
              }
            </>
          )}
        </div>

        {loading && page === 1 ? <PeoplesSkeleton /> : people.length ? (
          <InfiniteScroll
            hasMore={!ended}
            dataLength={people?.length ?? 0}
            next={fetchSimilarPeople}
            className="o-x-hidden o-y-hidden"
            loader={loading && <PeoplesSkeleton />}
          >
            <Row className="Grid-width-row" gutter={[24, 0]} style={{ margin: '0' }}>
              {people.map((person) => (
                <Col xxl={{ span: 4 }} xl={{ span: 4 }} lg={{ span: 6 }} className="">
                  <DragWrapper key={person?.personId} isDrag={isDrag}>
                    <PeopleCardLarge
                      key={person?.personId}
                      personId={person?.personId}
                      persona={person?.archeType?.color}
                      logo={person?.personImage}
                      personName={person?.personName}
                      tag={person?.personDesignation}
                      personaType={person?.archeType.name}
                      wrapperColor={backgroundColorParsing(person?.personalityTypes || [])}
                      personalityTypesColors={person?.personalityTypes || []}
                      hidePersonality={false}
                      company={person.companyName}
                      follow={!person.personAdded}
                      personAddedFlag={person.personAdded}
                    />
                  </DragWrapper>
                </Col>
              ))}
            </Row>
          </InfiniteScroll>
        ) : <NoPersonsFound title="No Person Found" />}
      </div>
      {allowGilroyGlobalFlag && chatbotAllowed ? (
        <div className="container chatbot-container">
          <Gilroy gilroyStates={gilroyStates} gilroyActions={gilroyActions} />
        </div>
      ) : null}
    </Wrapper>
  );
};
export default SimilarPeople;
